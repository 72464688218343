@import "../../styles/main.scss";

// PlayStoreReview
.play__store--review {
  position: relative;
  padding: 20px 0 20px 0;

  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .card {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    margin: 5px;
  }

  .user__info {
    @include flexbox(space-between);
  }

  .left__info {
    @include flexbox(space-between);

    h6 {
      font-size: 1.2rem;
      font-weight: 600;
    }

    p {
      font-size: 0.825rem;
      color: $gray-color;
      margin: 2px 0 0;
    }
  }

  .rate {
    padding-left: 30px;
    @include flexbox;

    span {
      color: $black-color;
      font-weight: 500;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }

  .inner__info {
    margin-left: 10px;
  }

  .message {
    font-size: 1rem;
    color: $gray-color;
    margin: 10px 0 10px 0;
  }

  .brand__play {
    @include flexbox(flex-start, center);

    span {
      color: $gray-color;
      margin-left: 5px;
    }
  }
}

:global(.react-multi-carousel-dot button) {
  background: #dddddd;
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
  border: none !important;
}

:global(.react-multi-carousel-dot--active button) {
  background: var(--primaryColor)!important;
}
