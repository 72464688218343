@import "./styles/main.scss";

/*------------------------------------------
 General CSS
------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 1rem;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: $black-color;
  background-color: $main-background-color;
  font-family: "Poppins", sans-serif;

  @include breakpoint-min($md) {
    background-color: #dcdcdc;
  }
}

a {
  color: var(--primaryColor);
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

strong {
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*----------------------------------
 Layout
----------------------------------*/
#root {
  height: 100%;
}
.wrapper {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: $main-background-color;
  min-height: 100%;
  height: 100%;

  &.auto-height {
    @include breakpoint-min($md) {
      height: auto;
    }
  }

  &.bg-white {
    background-color: #ffffff;
  }
}

.vertical-column-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.vertical-column-top {
  position: relative;
}

.vertical-column-bottom {
  padding: 1rem 0;
}

.bottom-button-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.5rem;
  background-color: #f6faff;
}

/*----------------------------------
 Forms
----------------------------------*/
.label {
  color: $black-color;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 2.6rem;
}

.label-sm {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.sub-label {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
}

.form-group {
  position: relative;
  margin-bottom: 12px;
}

.form-control,
input {
  width: 100%;
  background-color: $main-background-color;
  border: none;
  padding: 17px 20px;
  box-shadow: 0 3px 10px rgba(176, 199, 212, 0.6);
  border-radius: 1.2rem;
  font-weight: 500;
  color: $black-color;
  font-family: "Poppins", sans-serif;
  margin-bottom: 6px;
  font-size: 1.2rem;

  &:focus {
    border: none;
    outline: none;
  }
}

input:disabled {
  background-color: #f9f9f9;
  color: #a0a0a0;
  cursor: not-allowed;
  box-shadow: none;
}

.form-select {
  width: 100%;
  background-color: $main-background-color;
  border: none;
  padding: 17px 16px;
  box-shadow: 0 3px 10px rgba(176, 199, 212, 0.6);
  border-radius: 1.2rem;
  font-weight: 500;
  color: $black-color;
  font-family: "Poppins", sans-serif;
  margin-bottom: 6px;
  font-size: 1.1rem;

  &:focus {
    border: none;
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("../public/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 8px top 18px;
}

select::-ms-expand {
  display: none;
}

::placeholder {
  color: #7a7a7a;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #7a7a7a;
}

.error-msg {
  color: red;
  font-size: 10px;
  display: block;
  margin-bottom: 5px;
  margin-left: 5px;
}

// .button {
//   background-color: $primary-color;
//   border: none;
//   padding: 12px 10px;
//   width: 100%;
//   color: #ffffff;
//   font-weight: bold;
//   font-size: 17px;
//   border-radius: 14px;
//   text-align: center;
//   position: relative;
//   overflow: hidden;

//   @include breakpoint-max($md) {
//     font-weight: 900;
//   }
// }

// .button:disabled {
//   opacity: 0.3;
// }

// Animated Button
// .shine {
//   position: absolute;
//   opacity: 0;
//   top: 3%;
//   left: 0;

//   background: linear-gradient(
//     to right,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(255, 255, 255, 0.03) 10%,
//     rgba(255, 255, 255, 0.6) 30%,
//     rgba(255, 255, 255, 0.85) 50%,
//     rgba(255, 255, 255, 0.85) 70%,
//     rgba(255, 255, 255, 0.85) 71%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

//   width: 10px;
//   height: 94%;
//   transform: skew(-20deg, 0deg);

//   animation: move 3s;
//   animation-iteration-count: infinite;
//   animation-delay: 1s;
// }

// @keyframes move {
//   0% {
//     left: 0;
//     opacity: 0;
//   }
//   5% {
//     opacity: 0;
//   }
//   48% {
//     opacity: 0.7;
//   }
//   80% {
//     opacity: 0;
//   }
//   100% {
//     left: 100%;
//   }
// }

.text-center {
  text-align: center;
}

// Hide scrollbar
::-webkit-scrollbar {
  width: 0px;
}
