// Transactions CSS

.transactions {
  position: relative;
  padding-bottom: 100px;

  .list {
    padding: 20px 0;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: calc(100% + 30px);
      height: 2px;
      background-color: #f0f0f0;
      bottom: 0;
      left: -15px;
    }

    &:last-child::after {
      display: none;
    }
  }

  .block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #585858;
    padding: 5px 0;
  }

  .amount {
    font-size: 14px;
    font-weight: 600;
    color: #1f1f1f;
  }

  .success {
    display: flex;
    align-items: center;

    span {
      color: #14d44a;
      margin-left: 5px;
    }
  }
}
