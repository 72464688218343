@import "../../styles/main.scss";

.exist__modal {
  padding: 1rem 3rem;
  text-align: center;

  img {
    width: 200px;
    margin: 20px auto;
  }

  h3 {
    font-size: 1.25rem;
    margin: 20px 0;
  }

  p {
    font-size: 1.1rem;
    margin: 10px 0;

    span {
      color: var(--primaryColor);
    }
  }
}

.reference__modal {
  padding: 1rem 3rem;

  h3 {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .reference-list {
    margin: 20px 0;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
    }
  }

  p {
    font-size: 1.1rem;
    margin: 10px 0;
  }
}

// Confirm Bank Modal
.confirm-bank-modal {
  padding: 25px 30px 20px 30px;

  h3 {
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 22px;
    text-align: center;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
  }

  .bank-details {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bank {
    margin-right: 20px;
    text-align: center;
    padding: 20px 0;

    img {
      margin: 6px auto;
      width: 44px;
    }
  }

  .account-number {
    p {
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      margin-bottom: 5px;
    }
  }

  .term-condition {
    display: flex;
    margin: 25px 0 10px 0;

    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      background: none;
      margin: 2px 7px 0 0;
      box-shadow: none;
    }

    p {
      text-align: left;
      font-size: 10px;
      color: #858585;
    }
  }
}
