@import "../../styles/main.scss";

/*-----------------------------------
 Contact Us CSS
-----------------------------------*/
.contact__us {
  position: relative;

  .list {
    display: flex;
    padding: 14px 12px;
    border-radius: 14px;
    align-items: center;
    margin: 24px 0;
    @include box-shadow;

    .icon {
      width: 46px;
      height: 46px;
      background-color: #ecf0f3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: var(--primaryColor);
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 65px);
      padding-left: 15px;
    }

    h5 {
      color: $black-color;
      font-weight: 600;
      font-size: 15px;
    }

    span {
      color: $gray-color;
      font-weight: 500;
      font-size: 12px;
    }

    .arrow {
      margin-right: -25px;
      position: relative;
      top: 1px;
    }
  }
}
