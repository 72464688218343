@import "../../styles/main.scss";

.salaryDateStyle {
  position: relative;

  .dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .date {
    width: 14%;
    box-shadow: 0 2px 8px rgba(176, 199, 212, 0.6);
    padding: 0.93rem;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    margin: 0.5rem 0.35rem;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background: var(--primaryColor);
      color: $white-color;
    }
  }
}
