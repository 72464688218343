// Custom Button Styles

.button__container {
  position: relative;
}

.custom__button {
  background: linear-gradient(to right, var(--primaryColor) 1%, var(--primaryColor) 50%, var(--secondaryColor) 100%);
  border: none;
  padding: 12px 10px;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  span {
    margin: 0 6px;
  }

  &.large {
    padding: 17px 10px;
    font-size: 18px;
  }

  &.outline {
    background: transparent;
    color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
  }
}

// Animation
.shine {
  position: absolute;
  opacity: 0;
  top: 3%;
  left: 0;

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 10%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

  width: 10px;
  height: 94%;
  transform: skew(-20deg, 0deg);

  animation: move 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.7;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 100%;
  }
}
