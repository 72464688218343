@import "../../styles/main.scss";

// Home Styles
.home {
  position: relative;
  padding: 20px 15px 0px 15px;
  background-color: $light-gray-color2;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;

  // Credit Score
  .credit__score {
    background-color: #ffffff;
    padding: 25px 20px 35px 20px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      width: calc(100% - 130px);
    }

    h3 {
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 2rem;
    }

    p {
      font-size: 1.25rem;
      color: $gray-color;
      margin: 5px 0 20px 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      border-radius: 10px;
      padding: 7px 16px;
      border: 1px solid var(--primaryColor);
      font-size: 1.1rem;

      span {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    .image {
      width: 130px;
    }
  }

  // Our Process
  .our__process {
    margin: 20px 0;
    background-color: #ffffff;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: space-around;

    & > div {
      flex: 1;
      text-align: center;
      padding: 10px;
    }

    img {
      width: 60px;
      margin: 8px auto;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  // Refer & Earn
  .refer__earn {
    padding: 15px 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  // Affordable & Accessible
  .affordable {
    padding: 10rem 15px 50px 15px;
    margin-left: -15px;
    margin-right: -15px;
    background: linear-gradient(to bottom, #f9f9f9 19%, #c6cbef 100%);

    h1 {
      font-weight: bold;
      color: #8b8b8b;
      font-size: 4rem;
      line-height: 5rem;
    }

    p {
      font-weight: bold;
      color: #8b8b8b;
      font-size: 1.375rem;
      margin-top: 15px;
    }
  }
}
