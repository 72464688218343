@import "../../styles/main.scss";

/*-----------------------------------
 Loan Approval CSS
-----------------------------------*/
.loanApprovalStyle {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  background-color: $main-background-color;

  .topHeader {
    // background-color: var(--primaryColor);
    background: linear-gradient(to bottom, var(--secondaryColor) 0%, var(--primaryColor) 40%, var(--primaryColor) 100%);
    border-bottom-left-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem;
    overflow: hidden;
  }

  .backButton {
    padding: 1.5rem 1rem 0.4rem 1rem;
    display: flex;
    align-items: center;
  }

  .message {
    text-align: center;
    color: $white-color;

    .firstText {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }

    .secondText {
      font-size: 1.4rem;
    }
  }

  .bgImage {
    padding-bottom: 14rem;
    // background-image: url("../../../public/images/cardbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    margin-top: -1rem;
    padding-top: 1rem;
  }

  .loanApproved {
    background-color: #ecf0f3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
    padding: 1.5rem 1rem 1.8rem 1rem;
    margin-top: -12.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    h3 {
      text-align: center;
      font-size: 2rem;
      color: $black-color;
      font-weight: 600;
    }

    p {
      font-size: 1.1rem;
      text-align: center;
    }

    h2 {
      text-align: center;
      font-size: 2.8rem;
      color: $black-color;
      font-weight: 600;
      margin: 0.5rem 0 1.5rem 0;
    }

    .images {
      text-align: center;

      img {
        width: 35%;
        margin: 0.8rem auto;
      }
    }
  }

  .amountDetails {
    display: flex;
    justify-content: space-between;
    font-size: 1.15rem;
    margin: 0.25rem 0;
    padding: 0.25rem 0;

    &.finalAmount {
      margin: 0.5rem 0;
      padding: 0.5rem 0;
      border-top: 1px solid #9f9f9f;
    }
  }

  .upgradeAmount {
    text-align: center;
    margin: 2rem 1rem 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--primaryColor);
    cursor: pointer;
  }

  .repaymentPlan {
    padding: 1.5rem;

    h4 {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 11px;
    }

    .right {
      text-align: right;
    }

    .repaymentBlock {
      display: flex;
      justify-content: space-between;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      color: $gray-color;
      margin-bottom: 3px;
    }

    .value {
      font-size: 14px;
      font-weight: 600;
      color: $black-color;
    }

    .days {
      color: var(--primaryColor);
      font-size: 10px;
      background: rgba(24, 43, 218, 0.1);
      padding: 2px 5px;
      border-radius: 2px;
      position: relative;
      top: -1px;
      margin-left: 2px;
    }
  }

  .totalAmount {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.5rem;
    font-weight: 500;

    p {
      font-size: 1.25rem;
      color: $gray-color;
    }

    h5 {
      text-align: left;
      font-size: 2rem;
      color: $black-color;
      font-weight: 600;
      margin: 1px 0;
      line-height: 100%;
    }

    .taxText {
      font-size: 0.875rem;
      color: $gray-color;
    }

    button {
      border: none;
      background-color: var(--primaryColor);
      color: $white-color;
      padding: 10px 18px 10px 24px;
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: 0.2px;
      border-radius: 14px;
      display: flex;
      align-items: center;
    }
  }
}
