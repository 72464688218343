.contact-message {
  margin: 22px 0 10px 0;
  color: #5a5a5a;
  font-size: 1.2rem;
  font-weight: 500;

  a {
    color: var(--primaryColor);
  }
}
