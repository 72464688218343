@import "../../styles/main";

.referenceCard {
  padding: 1.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  margin-bottom: 2rem;
}

.refHeader {
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

label {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.radioButton {
  width: 18px;
  height: 18px;
  border: 2px solid var(--primaryColor);
  display: block;
  border-radius: 50%;
  position: relative;
  margin-right: 15px;

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--primaryColor);
    border-radius: 50%;
    top: 2px;
    left: 2px;
    display: none;
  }

  &.active::before {
    display: block;
  }
}
