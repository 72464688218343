@import "../../styles/main.scss";

// Main Header
.main-header {
  padding: 25px 0 35px 0;
  // padding: 2rem 0 3.2rem 0;
  background-color: #f6faff;

  .header-inner {
    display: flex;
    align-items: center;
  }

  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .back-icon {
    width: 30px;
    height: 30px;
    position: relative;
    top: 1px;
    z-index: 999;
  }

  .header-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #1f1f1f;
    text-align: center;
    width: 100%;
    padding-right: 40px;
  }
}

// Progress Bar
.header-progress {
  width: 96%;
  margin: 0 auto;
  position: relative;
  text-align: right;

  .text {
    color: var(--primaryColor);
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .progress-bar {
    background-color: #e3e2e2;
    height: 5px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .progress-completed {
    position: absolute;
    background-color: var(--primaryColor);
    height: 100%;
  }
}
