@import "../../styles/main.scss";

// DashboardHeader Styles

.dashboard__header {
  background-color: #ffffff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(192, 206, 255, 0.5);
  position: relative;
  z-index: 9999;

  .brand__info {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 44px;
    margin-right: 10px;
  }

  .brand__name {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    text-transform: capitalize;
  }

  .notification {
    width: 44px;
    height: 44px;
    border: 1px solid $light-gray-color;
    background-color: $light-gray-color2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .dot {
    width: 6px;
    height: 6px;
    background-color: #f33c3c;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    right: 14px;
  }
}
