@import "../../styles/main.scss";

/*-----------------------------------
 Finbox
-----------------------------------*/
.finbox__error {
  padding: 20px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 480px;

  img {
    width: 100px;
    margin: 10px auto;
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
    color: $red-color;
  }

  p {
    font-size: 1.5rem;
    color: var(--primaryColor);
    font-weight: 600;
    margin-bottom: 1rem;
  }

  button {
    margin: 1rem 0;
  }
}

.finbox__reject {
  position: relative;

  .reason__msg {
    color: #1f1f1f;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 1.6rem 0;
    line-height: 2rem;
  }

  .list {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    .icon {
      width: 60px;
    }

    img {
      width: 60px;
      height: 60px;
    }

    .content {
      width: calc(100% - 65px);
      padding-left: 5px;
    }

    h4 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
      color: $gray-color;
      margin: 0;
    }
  }
}
