@import "../../styles/main.scss";

.select-option {
  background-color: $main-background-color;
  padding: 12px;
  text-align: center;
  font-size: 16px;
  color: $black-color;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(176, 199, 212, 0.5);
  border-radius: 14px;
  margin: 18px 0;
  cursor: pointer;

  &.checked {
    background-color: var(--primaryColor);
    color: $white-color;
  }
}
