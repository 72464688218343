// Documents

.documents {
  position: relative;
  padding-bottom: 120px;
  padding-top: 5px;

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #585858;
    font-weight: 500;
    margin: 15px 0;
  }

  .download {
    cursor: pointer;
    padding: 5px;
  }
}
