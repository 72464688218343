@import "../../styles/main.scss";

.panVerifyStyles {
  position: relative;

  .check {
    position: absolute;
    top: 22px;
    right: 20px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .information {
    padding: 12px 20px 20px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 25px 0;
    border-radius: 1rem;

    p {
      color: #5a5a5a;
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .buttons {
      display: flex;
      justify-content: space-evenly;
    }

    button {
      border: 1px solid var(--primaryColor);
      color: var(--primaryColor);
      padding: 0.625rem 2.5rem;
      background-color: #f6faff;
      border-radius: 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0 10px;
    }
  }
}
