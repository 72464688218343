// EMICalendar CSS

.emiCalendar {
  position: relative;
  padding-top: 10px;
  padding-bottom: 120px;

  .emiContainer {
    margin: 5px 0 15px 0;
    overflow: hidden;
  }

  .emiList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    cursor: pointer;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .number {
    background-color: #efefef;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1f1f1f;
    font-size: 12px;
    border-radius: 3px;
    margin-right: 8px;
    font-weight: 500;
  }

  .date {
    font-size: 14px;
    margin-right: 10px;
    color: #585858;
    width: 90px;
  }

  .status {
    font-size: 9px;
    background: rgba(14, 187, 83, 0.1);
    color: #0ebb53;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .price {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;

    span {
      margin-right: 4px;
    }
  }

  .emiDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 18px 4px 30px;
    font-size: 11px;
  }

  .totalAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 8px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 100%;
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);

    p {
      font-size: 13px;
      color: #585858;
    }

    h5 {
      text-align: left;
      font-size: 30px;
      color: #1f1f1f;
      font-weight: 600;
      margin: 1px 0;
      line-height: 100%;
    }

    .taxText {
      font-size: 10px;
      color: #585858;
    }

    button {
      border: none;
      background-color: var(--primaryColor);
      color: #ffffff;
      padding: 10px 15px 10px 20px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.2px;
      border-radius: 14px;
      display: flex;
      align-items: center;
    }
  }
}
