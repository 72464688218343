@import "../../styles/main.scss";

// LoanRepayment Styles

.loanRepayment {
  position: relative;
  background: var(--lightColor);
  padding: 0px 20px 30px 20px;
  border-radius: 15px;
  overflow: hidden;

  .message {
    background:  var(--darkColor);
    padding: 10px 12px;
    color: #ffffff;
    font-size: 11px;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    margin-bottom: 25px;

    span {
      position: relative;
      z-index: 9999999;
      font-weight: 500;
    }

    &::after {
      position: absolute;
      content: "";
      width: 32px;
      height: 55px;
      right: -8px;
      top: -18px;
      background-color: var(--darkColor);
      border-bottom-right-radius: 15px;
      transform: rotate(25deg);
    }

    &::before {
      position: absolute;
      content: "";
      width: 32px;
      height: 55px;
      left: -8px;
      top: -18px;
      background-color: var(--darkColor);
      border-bottom-left-radius: 15px;
      transform: rotate(-25deg);
    }
  }

  .overdueMessage {
    background-color: #f33c3c;

    &::after {
      background-color: #f33c3c;
    }

    &::before {
      background-color: #f33c3c;
    }
  }

  .title {
    font-size: 15px;
    color: #1f1f1f;
    font-weight: 600;
    margin: 20px 0 5px 0;
  }

  .paymentAmount {
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 40px;
      color: #1f1f1f;
      font-weight: 600;
    }

    .overdueDate {
      color: #f33c3c;
      background: var(--primaryColor);
      font-size: 10px;
      padding: 3px 8px;
      border-radius: 5px;
      margin-left: 15px;
      font-weight: 500;
      position: relative;
      top: 1px;
    }
  }

  .greetMessage {
    font-size: 13px;
    margin: 10px 0 30px 0;
    font-weight: 500;
  }

  .dueDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    font-size: 17px;
    font-weight: 500;

    .dateValue {
      color: var(--primaryColor);
    }

    .dateValueOverdue {
      color: #f33c3c;
    }
  }

  .button {
    width: 86%;
    margin: 0 auto;
  }
}
