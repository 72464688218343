@import "../../styles/main.scss";

// EnterMobile Styles
.home {
  position: relative;

  .label {
    color: #1f1f1f;
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 2.6rem;
  }

  .sub__label {
    color: #5a5a5a;
    font-size: 15px;
    font-weight: 400;
  }

  .call__icon {
    position: absolute;
    top: 16px;
    left: 15px;
  }

  .form__input {
    width: 100%;
    background-color: #f6faff;
    border: none;
    padding: 18px 20px;
    border-radius: 10px;
    font-weight: 500;
    color: #1f1f1f;
    font-family: "Poppins", sans-serif;
    margin-bottom: 6px;
    font-size: 1.1rem;
    border: 1px solid var(--primaryColor);
    text-align: center;
  }
}

.footerBar {
  margin: 0px 0;
  position: absolute;
  padding: 0 20px 100px 20px;
  background-color: #f6faff;
  left: 0;

  @include breakpoint-min($md) {
    padding-top: 50px;
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;

    @include breakpoint-max($md) {
      margin-top: 50px;
    }
  }

  .disc li {
    list-style-type: disc;
    margin-left: 15px;
    margin-top: 5px;
  }
}
