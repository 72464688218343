// Loan Summary CSS

.loanSummary {
  background: linear-gradient(to bottom, var(--secondaryColor) 0%, var(--primaryColor) 40%, var(--primaryColor) 100%);
  padding: 20px 20px 150px 20px;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;

  .header {
    position: relative;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 40px;

    .backArrow {
      position: absolute;
      left: -10px;
      top: -2px;
    }
  }

  .paymentAmount {
    margin-bottom: 20px;

    p {
      color: #ffffff;
      font-size: 15px;
    }

    h3 {
      color: #ffffff;
      font-size: 34px;
      font-weight: bold;
    }
  }
}

.loanDetails {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 15px 15px 5px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -150px;

  .title {
    color: #1f1f1f;
    font-weight: 600;
    text-align: center;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .detailList {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    & > div:last-child {
      width: 40%;
      margin-left: 10px;
    }
  }

  .label {
    color: #9f9f9f;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .value {
    color: #1f1f1f;
    font-weight: 600;
    font-size: 13px;

    &.dueDate {
      color: var(--primaryColor);
    }
  }

  .due {
    background: #ecf0f3;
    font-size: 10px;
    font-weight: 500;
    padding: 1px 6px;
    border-radius: 3px;
    margin-left: 5px;
    color: var(--secondaryColor);
  }
}
